.footerWrapper {
  background-color: #2d3032;
  border-bottom: 10px solid #245194;
  border-top: 10px solid #245194;
  bottom: 0;
  clear: both;
  color: #fff;
  font-size: 15px;
  line-height: 1.5em;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 5000;
}

.hr {
  border-image: none;
  border: 1px solid #fff;
  margin-bottom: 20px;
  margin-top: 20px;
}

.footerHeading {
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  color: #fff !important;
  font-family: 'Open Sans', Helvetica, Arial,sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 10px;
  margin-top: 20px;
  margin: .67em 0;
}
